<template>
  <div
    class="sticky top-[--layout-offset-top] z-10 -mb-8 w-full pt-4 transition-all duration-navigation before:absolute before:left-0 before:right-0 before:top-0 before:-z-[1] before:h-12 before:bg-white before:bg-white/95 before:backdrop-blur before:content-['']"
  >
    <app-page-wrapper class="md:px-6">
      <div
        class="shadow-b-lg flex items-center justify-between gap-4 rounded border border-gray-50 bg-white p-3 md:items-start"
      >
        <slot />
      </div>
    </app-page-wrapper>
  </div>
</template>
