<template>
  <listbox
    v-slot="{ open }"
    v-model="model"
    as="div"
    class="relative"
    :multiple="multiple"
  >
    <listbox-button
      class="rounded w-full border px-4 h-[50px] md:h-[38px] flex gap-2 items-center outline-none transition hover:bg-primary-50 focus-visible:ring-2 focus-visible:ring-primary-200"
    >
      <app-icon
        v-if="icon"
        class="text-subtle transition"
        :class="{ '!text-primary-500': open || isNotEmpty }"
        :icon="icon"
        :size="22"
      />

      <span class="whitespace-nowrap flex">{{ label }}</span>

      <span v-if="isNotEmpty" class="flex">
        <slot name="selection" v-bind="{ selection: model }">
          <span class="font-semibold">{{ valueLabel }}</span>
        </slot>
      </span>

      <span
        v-else-if="placeholder || $slots.placeholder"
        class="font-semibold flex"
      >
        <slot name="placeholder">
          {{ placeholder }}
        </slot>
      </span>

      <div v-if="isNotEmpty" class="grid flex-auto place-content-end">
        <button
          class="grid h-[20px] w-[20px] place-content-center rounded bg-primary-50 p-[2px] text-primary-950 hover:bg-primary-200"
          @click="reset($event, { preventDefault: !open })"
        >
          <app-icon icon="ph:x" :size="14" />
        </button>
      </div>
    </listbox-button>

    <transition
      enter-active-class="transition"
      enter-from-class="opacity-0 translate-y-2"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-2"
    >
      <listbox-options
        class="absolute z-50 min-w-[max(100%,250px)] p-1 cursor-pointer rounded bg-white shadow-lg outline-none"
        :class="positionClasses"
      >
        <div v-if="$slots['prepend-options']" class="mb-2">
          <slot name="prepend-options" />
        </div>

        <ul class="overflow-auto max-h-[300px]">
          <listbox-option
            v-for="item in items"
            :key="item[itemValue]"
            v-slot="{ active }"
            as="template"
            :value="get(item, itemValue)"
            ><li
              class="flex items-center rounded justify-between gap-3 px-3 py-2 group"
              :class="{
                'bg-primary-50': active,
                'font-semibold': isSelected(item),
              }"
            >
              <app-icon
                v-if="multiple"
                :class="{
                  'text-subtle group-hover:text-primary': !isSelected(item),
                  'text-primary': isSelected(item),
                }"
                :icon="
                  isSelected(item) ? 'ph:check-square-fill' : 'ph:square-bold'
                "
                :size="20"
              />

              <div class="flex-auto">
                <slot name="item" v-bind="{ item }">
                  {{ get(item, itemText) }}
                </slot>
              </div>
            </li>
          </listbox-option>
        </ul>
      </listbox-options>
    </transition>
  </listbox>
</template>

<script
  lang="ts"
  setup
  generic="T extends Record<string, any>, R extends string | number"
>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import get from "lodash/get";

import type { DropdownPosition } from "../composables/dropdown-position.hook";
import { useDropdownPosition } from "../composables/dropdown-position.hook";

const properties = withDefaults(
  defineProps<{
    modelValue?: R | R[];
    label?: string;
    valueLabel?: string;
    placeholder?: string;
    multiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items?: T[] | null;
    itemText: string;
    itemValue: string;
    position?: DropdownPosition;
    icon?: string;
  }>(),
  {
    modelValue: undefined,
    label: undefined,
    valueLabel: undefined,
    placeholder: undefined,
    items: undefined,
    position: "bottom-left",
    icon: undefined,
  },
);

const model = useVModel(properties, "modelValue");
const { class: positionClasses } = useDropdownPosition(properties.position);

const isNotEmpty = computed(() => {
  if (Array.isArray(properties.modelValue))
    return properties.modelValue.length > 0;

  return (
    properties.modelValue !== undefined &&
    properties.modelValue !== null &&
    properties.modelValue !== ""
  );
});

const reset = (
  event: MouseEvent,
  { preventDefault }: { preventDefault: boolean },
) => {
  if (preventDefault) {
    event.preventDefault();
  }

  model.value = properties.multiple ? ([] as typeof model.value) : undefined;
};

const isSelected = (item: T) => {
  if (!isNotEmpty.value) return false;

  if (Array.isArray(properties.modelValue))
    return properties.modelValue.includes(get(item, properties.itemValue));

  return properties.modelValue === get(item, properties.itemValue);
};
</script>
