<template>
  <app-dropdown-filter
    v-model="model"
    icon="ph:magnifying-glass"
    item-text="label"
    item-value="slug"
    :items="data"
    :label="$t('tag.filter.label')"
    multiple
  >
    <template #selection>
      <div class="flex items-center gap-1">
        <template v-if="selectedTags.length > 0">
          <tag-chip
            class="hidden md:flex"
            :data-cy-selected-tag-slug="selectedTags[0]?.slug"
            size="small"
            :tag="selectedTags[0]!"
          />

          <app-chip
            class="bg-primary-100 text-primary md:hidden"
            size="small"
            >{{ selectedTags.length }}</app-chip
          >
        </template>

        <app-chip
          v-show="selectedTags.length > 1"
          class="hidden bg-primary-100 text-primary md:flex"
          size="small"
          variant="plain"
          >+ {{ selectedTags.length - 1 }}</app-chip
        >
      </div>
    </template>

    <template #item="{ item }">
      <div
        class="flex items-center gap-2 whitespace-nowrap"
        :data-cy-tag-option-id="item.id"
      >
        {{ item.label }}
      </div>
    </template>

    <template #placeholder>
      <app-chip class="bg-primary-100 text-primary" size="small"
        >{{ $t("tag.filter.all_label") }}
      </app-chip>
    </template>
  </app-dropdown-filter>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  modelValue?: string[];
}>();

const model = useVModel(properties, "modelValue");
const { data } = useTagList(
  { used: true, depth: 0 },
  { server: false, lazy: true },
);

const selectedTags = computed(() => {
  if (!properties.modelValue) return [];

  return (
    data.value?.filter((tag) => properties.modelValue!.includes(tag.slug)) ?? []
  );
});
</script>
